
import './App.css';
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './components/header';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Job from './pages/job';
import Contact from './pages/contact';
import Services from './pages/services';
import Jobdesc from './pages/jobdesc';
import NotFound from './pages/notfound';
import ReactGA from 'react-ga4';




 

 

function App() {
  return (
    <BrowserRouter>
      <div className='app'>
            <Header/> 
            <Navbar/>
           
      <Routes>
        <Route path="/" exact element={<Home />}/>
        <Route path="/about"  element={<About />}/>
        <Route path="/job" element={<Job />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/jobdesc" element={<Jobdesc />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="" element={<NotFound />}/>
           </Routes>
      <Footer/>
      </div>
      </BrowserRouter>
    

  );
}

export default App;
