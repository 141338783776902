import React from "react";
import "../App.css";
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
// import Animate from 'animate.css-react';
import 'animate.css/animate.compat.css'; // Import Animate.css styles
import {Animated} from "react-animated-css";
import aboutusflipped2 from "../images/aboutusflipped2.png";
import flowchart from "../images/flowchart.png";
import abouthero from "../images/newabouthero.jpg";
import underaboutus from "../images/underaboutus.jpg";
function About() {
 
  
  return (
    <div>
      {/* <div>
        <div>
          <img
            src={abouthero}
            class="d-block w-100  aboutheroimg"
            alt="An aged woman experiencing peace of mind."
          />
          <div class="carousel-caption mobile-carousel-caption d-block">
            <div className="lhs lhsmobile">
              <div className="lhsheading">
                {" "}
                <span class="thick-dot"></span>{" "}
                <span className="ps-1"> ABOUT BROOKS HEALTH AND CARE </span>{" "}
              </div>
              <div className="lhssubtitle pt-3">
                Caring for You Every Step of the Way{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="aboutheroreplacement p-md-5 p-4"  style={{
            backgroundImage: `url(${abouthero})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            backgroundColor: "rgb(0 0 0 / 70%)"
            
          }}>
      <div className="aboutheroreplacementbox">
              <div className="lhsheading">
                {" "}
                <span class="thick-doFt"></span>{" "}
                <span className="ps-1"> ABOUT BROOKS HEALTH AND CARE </span>{" "}
              </div>
              <div className="lhssubtitle pb-2 pt-3">
                Caring for You Every Step of the Way{" "}
              </div>
            </div>
      </div>

      <div className="aboutsegment1  p-4 p-md-5">
        We are a reliable company that provides compassionate and personalised
        healthcare services. Our range of services is tailored to meet the
        unique needs of each individual we serve, including Care Services,
        Personalised Caregiving, and Medical Staffing.
        <br /> <br />
        Our ultimate goal is to provide exceptional care to those who depend on
        us, ensuring they receive unparalleled service and support. At Brooks
        Health and Care, we understand that your health and wellbeing are of
        utmost importance. That's why we are dedicated to providing you with
        unwavering support, listening to your concerns, and working
        collaboratively with you to meet your unique needs. We're here to help
        you make informed decisions and put you back in control of your health
        journey.
        <br /> <br />
        <div className="pb-2 pb-md-1">
          We know that empowerment is the key to having control over the
          decisions and actions that shape our lives.
        </div>
        <br />{" "}
        <span className="fw-bold abouthereforyou">WE ARE HERE FOR YOU.</span>
      </div>
      <div className="greybg">
        <div
          className="aboutsegment2 p-4 p-md-5 "
          style={{
            backgroundImage: `url(${underaboutus})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            backgroundColor: "rgb(0 0 0 / 90%)",
          }}
        >
          <div className="missionbox">
            <div className="d-flex pt-5 ">
              <div className="homeSegment1aboutusline missionlinewhite"></div>
              <div className="homeSegment1aboutus missionwhite ps-2 ">OUR MISSION</div>
            </div>
            <div className="homeSegment3intro py-3">
              At Brooks Health and Care, <br />
              our mission is to:
            </div>
            <div className="aboutsegment2desc py-2">
              <ul className="missionlist">
                <li>
                  To support our service users in maintaining their independence
                  and quality of life in their homes and communities.{" "}
                </li>
                <li>
                  {" "}
                  To provide top-tier service with a friendly, caring, and
                  empathetic approach.{" "}
                </li>
                <li>
                  To provide appropriate, trained, and skilled staff that fit
                  our service user needs.{" "}
                </li>
                <li>To provide continuity of care wherever possible. </li>
                <li>
                  To respect the dignity and privacy of our service while
                  promoting their utmost wellbeing and development.{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="visionbox">
            <div className="d-flex pt-5 flexii  text-end">
              <div className="homeSegment1aboutusline missionlinewhite"></div>
              <div className="homeSegment1aboutus missionwhite ps-2 text-end">
                OUR VISION
              </div>
            </div>
            <div className="homeSegment3intro homeSegment3introii py-3">
              At Brooks Health and Care, <br />
              our vision is to:
            </div>
            <div className="aboutsegment2iidesc py-2">
              At Brooks Health and Care, we're all about providing first-class
              care for our service users. Our mission is to maintain their
              independence and quality of life in their homes and communities
              while respecting their dignity and promoting well-being and
              development. We achieve this by recruiting, training, and
              retaining the best staff who share our compassion, respect, and
              honesty values. We believe in working collaboratively with
              everyone involved in caring for our service users, and we always
              aim to provide the best possible service with a friendly, caring,
              and empathetic approach. So, if you're looking for care that
              genuinely cares, choose Brooks Health and Care - where first-class
              care is our top priority!
            </div>
          </div>
        </div>
      </div>
      <div className="values p-md-5 p-4">
        <div className="valuesheading pb-md-5 pb-2">Our Values</div>
        <div>
          <div className="valuesdesc">
            To achieve our vision and mission, we have developed a set of values
            that run through our care, recruitment, training, and development
            processes. Our values include compassion and empathy, respect and
            dignity, openness and honesty, collaborative working, commitment to
            quality of care, and caring with a smile.
          </div>

          <div>
            <div>
              <div className="valuesdesc pt-4">
                <span className="valuessubheading">
                  Compassion and Empathy:{" "}
                </span>{" "}
                They are at the heart of everything we do for our service users.
                They combine humanity, kindness, and care.{" "}
              </div>
            </div>
            <div>
              <div className="valuesdesc pt-3">
                <span className="valuessubheading">Respect and Dignity: </span>
                We value each of our service users as individuals and use a
                person-centred approach. We respect their aspirations and
                commitments and seek to understand and promote their priorities,
                needs, abilities, and limitations. When providing care, we treat
                our service users with dignity, aim to support and empower them
                and respect their right to make their own decisions.
              </div>
            </div>
            <div>
              <div className="valuesdesc pt-3">
                <span className="valuessubheading">Openness and honesty: </span>
                We are open and honest about our service, and we provide
                transparent fees with no hidden costs. We believe in a
                non-hierarchical and "open door" approach, whereby any staff
                member can speak to senior management and directors.
              </div>
            </div>
            <div>
              <div className="valuesdesc pt-3">
                <span className="valuessubheading">
                  Collaborative Working:{" "}
                </span>
                This is key to our success. We consider the views of all those
                involved in caring for our service users, including social
                workers, family members, friends, doctors, nurses, office staff,
                carers, and support workers. Each carer and support worker are a
                valued part of our team, and we listen to them and value their
                contribution and input.
              </div>
            </div>
            <div>
              <div className="valuesdesc pt-3">
                <span className="valuessubheading">
                  Commitment to quality of care:{" "}
                </span>
                We can only provide the best possible care and support if we
                recruit, train, and retain the best staff who share our values
                and vision. We value our staff's commitment and passion for
                providing our clients with great care, and we recognise that
                mistakes do occur. When they do, we expect an honest reflection
                from all those involved to prevent future errors.
              </div>
            </div>
            <div>
              <div className="valuesdesc pt-3">
                <span className="valuessubheading">Caring with a smile: </span>
                Everyone responds with a smile. No matter how busy life is,
                there is always time for a smile. We aim to recruit cheerful and
                empathetic staff and expect them to try to leave their problems
                at home and to do their best to bring a smile to all those they
                see or speak to during the working day. This applies to office
                staff using phones for much of the day.
                <br /> <br />
                At Brooks Health and Care, we're all about providing care that
                genuinely cares. Choose us for first-class care that puts our
                service users' needs and well-being first.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutsegment3 p-4 p-md-5">
        <div className="d-flex pt-5 ">
          <div className="homeSegment1aboutusline"></div>
          <div className="homeSegment1aboutus ps-2 ">WHY CHOOSE US?</div>
        </div>
        <div className="homeSegment3intro py-4">
          Discover why Brooks Health and Care is your <br />
          top choice for home health care.
        </div>
        <div className="aboutsegment3container">
        <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">

            <div>
              <i class="aboutsegment3icon fa-solid fa-heart-circle-check"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">Compassionate Care</div>
            <div className="aboutsegment3boxdesc">
              At Brooks Health and Care, we prioritize compassion in every
              interaction. Our dedicated team of caregivers and healthcare
              professionals is committed to providing empathetic and supportive
              care to our service users.
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
            <div>
              <i class="fa-solid aboutsegment3icon fa-address-card"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">
              Personalized Approach
            </div>
            <div className="aboutsegment3boxdesc">
              We understand that each individual's needs are unique. That's why
              we take a personalized approach to care, tailoring our services to
              meet the specific needs and preferences of each client.
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
            <div>
              <i class="fa-solid fa-people-group aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">Experienced Team</div>
            <div className="aboutsegment3boxdesc">
              With years of experience in the healthcare industry, our team
              brings a wealth of knowledge and expertise to every interaction.
              From support workers, carers to nurses, we have the skills and
              experience.
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
            <div>
              <i class="fa-solid fa-address-card aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">
              Comprehensive Services
            </div>
            <div className="aboutsegment3boxdesc">
              We offer a range of healthcare services, including Domiciliary
              Care (Home Care Support), Palliative Care, Companionship, Live In
              Care, Nights/Sleep-In, Wake-In Nights, Respite, 1-1 Support, Care
              Support for Travel and more, Medical Staffing Whatever your needs,
              we have the services to support you.
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
            <div>
              <i class="fa-solid fa-star aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-3">
              Commitment to Excellence
            </div>
            <div className="aboutsegment3boxdesc">
              We are dedicated to upholding the highest standards of excellence
              in everything we do. From our rigorous caregiver training programs
              to our commitment to continuous improvement, we strive for
              excellence in all aspects.
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
            <div>
              <i class="fa-solid fa-circle-check aboutsegment3icon"></i>{" "}
            </div>
            <div className="aboutsegment3boxtitle py-3">Trusted Partner</div>
            <div className="aboutsegment3boxdesc">
              When you choose Brooks Health and Care, you're choosing a trusted
              partner in your health journey. We are committed to building
              long-lasting relationships based on trust, reliability, and
              exceptional care.
            </div>
          </ScrollAnimation>
        </div>
      </div>
      {/* <div className="aboutsegment4  p-4 p-md-5">
        <div className="d-flex pt-5 ">
          <div className="homeSegment1aboutusline"></div>
          <div className="homeSegment1aboutus ps-2 ">OUR DEDICATED TEAM </div>
        </div>
        <div className="homeSegment3intro py-4"> Company Structure</div>
        <div className="py-4">
          {" "}
          <img src={flowchart} alt="flowchart" className="flowchart" />{" "}
        </div>
      </div> */}
    </div>
  );
}
export default About;
