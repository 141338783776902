import React from "react";
import uprightarrow from "../images/uprightarrow.png";
import jobdeschero from "../images/jobdeschero.jpg";
import applicationform from "../images/ApplicationForm.pdf";


function Jobdesc() {
  return (
    <div className="">
      {/* <div>
        <div class="">
          <img
            src={jobdeschero}
            class="d-block w-100 aboutheroimg"
            alt="An aged woman experiencing peace of mind."
          />
          <div class="carousel-caption d-block">
            <div className="lhs lhsmobile">
              <div className="lhsheading">
                {" "}
                <span class="thick-dot"></span>{" "}
                <span className="ps-1">
                  {" "}
                  JOIN OUR TEAM AT BROOKS HEALTH AND CARE{" "}
                </span>{" "}
              </div>
              <div className="lhssubtitle pt-3">
                Explore Exciting Career Opportunities with Brooks{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="aboutheroreplacement p-md-5 p-4"
        style={{
          backgroundImage: `url(${jobdeschero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          backgroundColor: "rgb(0 0 0 / 70%)",
        }}
      >
        <div className="aboutheroreplacementbox">
          <div className="lhsheading">
            {" "}
            <span class="thick-dot"></span>{" "}
            <span className="ps-1">
              JOIN OUR TEAM AT BROOKS HEALTH AND CARE{" "}
            </span>{" "}
          </div>
          <div className="lhssubtitle pt-3 pb-1">
            Explore Exciting Career Opportunities with Brooks{" "}
          </div>
        </div>
      </div>
      <div className="px-md-5 px-4 pt-5 pb-1">
        <div className="jobdescsegment1">
          <div className="backbutton">
            <a href="/job" style={{ color: "black" }}>
              <i class="fa-solid fa-arrow-left-long pe-1"></i> BACK
            </a>
          </div>
          <div className="jobdescsegment1subtitle pt-4">
            <div>
              Job Title: Domiciliary Care Worker with Full UK Driving Licence
            </div>
            <div className="fs-4 pt-1">Location: Biggleswade, Bedford</div>
          </div>
        </div>
      </div>
      <div className="horizontal-border-line pt-3"></div>
      <div className="greybg">
        <div className="jobdescsegment2 p-4  p-md-5">
          <div className="jobdescsegment2heading "> Job Summary</div>
          <div className="jobdescsegment2list pt-2">
            We are seeking a dedicated and compassionate Domiciliary Care Worker
            with a full and valid UK driving licence to join our team. As a
            Domiciliary Care Worker, you will provide personal care and support
            to clients in their homes, promoting their independence and
            enhancing their quality of life. This role requires travel between
            clients’ homes, so a valid UK driving licence and access to a
            reliable vehicle are essential.
          </div>
          <div className="jobdescsegment2heading pt-4">
            Key Responsibilities
          </div>
          <div className="jobdescsegment2list pt-2">
            <ol>
              <li>
                {" "}
                Provide personal care and assistance with daily living
                activities, including bathing, dressing, and grooming.
              </li>
              <li>
                {" "}
                Assist clients with mobility and physical activities, ensuring
                their safety and comfort.
              </li>
              <li>
                {" "}
                Administer medication according to care plans and company
                policies.
              </li>
              <li>
                {" "}
                Prepare and serve meals, ensuring dietary requirements and
                preferences are met.
              </li>
              <li>
                {" "}
                Perform light household tasks such as cleaning, laundry, and
                shopping.
              </li>
              <li> Provide companionship and emotional support to clients.</li>
              <li>
                {" "}
                Monitor and report any changes in clients’ health or wellbeing
                to the appropriate professionals.
              </li>
              <li>
                {" "}
                Maintain accurate and up-to-date records of care provided.
              </li>
              <li>
                {" "}
                Follow care plans and instructions from healthcare professionals
                and family members.
              </li>
              <li>
                {" "}
                Comply with all health and safety regulations and company
                policies.
              </li>
            </ol>
          </div>
          <div className="jobdescsegment2heading pt-3">Requirements</div>
          <div className="jobdescsegment2list pt-2">
            <ol>
              <li>
                {" "}
                Valid Full UK driving licence and access to a reliable vehicle.
              </li>
              <li>
                {" "}
                Previous experience in a similar care role is preferred but not
                essential; full training will be provided.
              </li>
              <li>
                {" "}
                NVQ Level 2 in Health and Social Care or willingness to work
                towards this qualification.
              </li>
              <li> Strong communication and interpersonal skills.</li>
              <li> Compassionate, patient, and empathetic nature.</li>
              <li> Ability to work independently and as part of a team.</li>
              <li>
                {" "}
                Flexibility to work various shifts, including mornings,
                evenings, weekends, and bank holidays.
              </li>
              <li> Right to work in the UK</li>
              <li> ⁠Preferable live in a commutable distance in the area.</li>
            </ol>
          </div>
          <div className="jobdescsegment2heading pt-3">Benefits</div>
          <div className="jobdescsegment2list pt-2">
            <ol>
              <li> Competitive salary with opportunities for overtime.</li>
              <li> Flexible working hours to suit your lifestyle.</li>
              <li>
                {" "}
                Comprehensive training and ongoing professional development
              </li>
              <li> Supportive and friendly working environment.</li>
              <li> Mileage allowance for travel between clients’ homes.</li>
              <li> Pension scheme.</li>
              <li> Employee Assistance Programme.</li>
              <li> Opportunities for career progression within the company.</li>
            </ol>
          </div>
          <div className="jobdescsegment2heading pt-3">
            {" "}
            Equal Opportunities
          </div>
          <div className="jobdescsegment2list pt-2">
            Brooks Health and Care is an equal opportunities employer. We
            welcome applications from all suitably qualified persons regardless
            of their race, sex, disability, religion/belief, sexual
            orientation, or age.
          </div>
          <div className="jobdescsegment2heading pt-3">Disability</div>
          <div className="jobdescsegment2list pt-2">
            The Equality Act 2010 defines disability as “a physical or mental
            impairment which has a substantial and long term adverse affect on a
            person‘s ability to carry out normal day to day activities". (You do
            not need to be registered as disabled). We are a ‘positive about
            disability’ employer. We welcome applicants with disabilities and we
            will guarantee an interview to anyone with a disability who meets
            the minimum essential criteria for the post. The recruitment team
            will be in contact prior to the interview to see if you need any
            assistance or reasonable adjustments to be made with
            interview arrangements.{" "}
          </div>
        </div>
      </div>
      {/* <div className="greyb">
        <div className="px-4 px-md-5 ">
          <div className="jobdescsegment1">
            <div className="jobdescsegment1subtitle pt-2">
              <div>Job Title: Support Worker</div>
            </div>
          </div>
        </div>
        <div className="jobdescsegment2  py-4 px-4 px-md-5">
          <div className="jobdescsegment2heading ">Skills and Knowledge</div>
          <div className="jobdescsegment2list pt-2">
            <ol>
              <li>
                Ability to demonstrate a warm, person centred and affirmative
                approach to people with learning disabilities, autistic spectrum
                disorders, mental health needs-
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Good verbal communication skills and ability to listen
                sensitively to others
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to engage with service users, to develop and sustain
                warm and trusting relationships
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to demonstrate basic insight and understanding into the
                needs of people with learning disabilities, autistic spectrum
                disorders, mental health needs.
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Written communication skills, sufficient to contribute to a
                record keeping system
                <div>
                  <span className="bluetext">
                    Assessed by Application Form and Interview,
                  </span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to work constructively and co-operatively as part of a
                team
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to work safely and responsibly without direct
                supervision in service user’s own homes
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to demonstrate initiative, self motivation and
                resourcefulness
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to liaise in a professional manner with other agencies
                and to work in a positive way with the families and friends of
                service users
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>

              <li>
                {" "}
                Understanding of the person centered aims and principles of
                Creative Support and ability to put these into practice
                <div>
                  <span className="bluetext">
                    Assessed by Application Form and Interview,
                  </span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to demonstrate respect for difference and diversity
                <div>
                  <span className="bluetext">
                    Assessed by Application Form and Interview,
                  </span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to provide emotional and practical support to service
                users
                <div>
                  <span className="bluetext">
                    Assessed by Application Form and Interview,
                  </span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                A non judgmental, accepting approach to working with people who
                may be challenging and the ability to cope in a mature way with
                conflict, distress and challenging behavior
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to work in a calm, patient and tolerant manner at a pace
                appropriate to the needs of the individual
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Ability to enable people to enjoy developmental opportunities
                without being exposed to unacceptable risks
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Requirement -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
            </ol>
          </div>
          <div className="jobdescsegment2heading pt-3">
            Experience and Qualifications
          </div>
          <div className="jobdescsegment2list pt-2">
            <ol>
              <li>
                {" "}
                Experience of providing care, support or other services to
                people with support
                <div>
                  <span className="bluetext">
                    Assessed by Application Form and Interview,
                  </span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Desirable</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Experience of supporting people with learning disabilities,
                autistic spectrum disorders, mental health needs
                <div>
                  <span className="bluetext">
                    Assessed by Application Form,
                  </span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Desirable</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Life experience and confidence in relating to people from a wide
                variety of backgrounds
                <div>
                  <span className="bluetext">
                    Assessed by Application Form and Interview,
                  </span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Possession of NVQ or other relevant social care qualification
                <div>
                  <span className="bluetext">
                    Assessed by Application Form,
                  </span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Desirable</span>{" "}
                </div>
              </li>
            </ol>
          </div>
          <div className="jobdescsegment2heading pt-3">Other</div>
          <div className="jobdescsegment2list pt-2">
            <ol>
              <li>
                {" "}
                Warm, respectful and positive approach when working with service
                users
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Willingness to work flexible hours according to needs of agency
                and service users{" "}
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Willingness to attend training courses and events
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                Willing to accept feedback and guidance and to be accountable to
                colleagues and managers
                <div>
                  <span className="bluetext">Assessed by Interview,</span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Essential</span>{" "}
                </div>
              </li>
              <li>
                {" "}
                To have a clean driving licence and access to a car
                <div>
                  <span className="bluetext">
                    Assessed by Application Form,
                  </span>{" "}
                  <span className="blacktext">Needs -</span>{" "}
                  <span className="bluetext">Desirable</span>{" "}
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div> */}
      <div className="px-5 pb-5 pt-md-3">
      <a
                    href={applicationform}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="applicationlink"
                  >
    
          <button className="testimonialbtn d-flex">
            <span className="pe-2">Apply </span>{" "}
            <img
              src={uprightarrow}
              alt="arrow"
              className="homeSegment6testimoialarrow"
            />
          </button>
          </a>
        
      </div>
    </div>
  );
}
export default Jobdesc;
