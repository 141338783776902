import React from "react";
import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import star from "../images/star.png";
import uprightarrow from "../images/uprightarrow.png";
import homeSegment5IMG from "../images/homeSegment5IMG.jpg";
import contacthero from "../images/contacthero.jpg";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div>
      {/* <div>
        <div class="">
          <img
            src={contacthero}
            class="d-block w-100  aboutheroimg"
            alt="An aged woman experiencing peace of mind."
          />
          <div class="carousel-caption d-block">
            <div className="lhs lhsmobile">
              <div className="lhsheading">
                {" "}
                <span class="thick-dot"></span>{" "}
                <span className="ps-1">
                  {" "}
                  JOIN OUR TEAM AT BROOKS HEALTH AND CARE{" "}
                </span>{" "}
              </div>
              <div className="lhssubtitle pt-3">
                Explore Exciting Career Opportunities with Brooks{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="aboutheroreplacement p-md-5 p-4"
        style={{
          backgroundImage: `url(${contacthero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          backgroundColor: "rgb(0 0 0 / 70%)",
        }}
      >
        <div className="aboutheroreplacementbox">
          <div className="lhsheading">
            {" "}
            <span class="thick-dot"></span>{" "}
            <span className="ps-1">
              {" "}
              JOIN OUR TEAM AT BROOKS HEALTH AND CARE
            </span>{" "}
          </div>
          <div className="lhssubtitle pb-1 pt-3">
            Explore Exciting Career Opportunities with Brooks{" "}
          </div>
        </div>
      </div>
      <div className="aboutsegment1 px-5  pt-5 pb-md-3">
        Have questions or need assistance? We're here to help. Feel free to
        reach out to us via phone, email, or by filling out the contact form
        below.
      </div>
      <div className="horizontal-border-line pt-3"></div>
      <div className="d-lg-flex p-4 p-md-5 contactformflex">
        <div className="slideLeft">
          <div className="contactheading">Connect with Us Today!</div>
          <div className="contactsubheading py-4">
            At Brooks Health and Care, we value your inquiries, feedback, and
            collaborations. Whether you are interested in our services, or have
            questions about our projects.
          </div>
          <div className="d-flex contacticoncontainer ">
            <div>
              <i class="fa-solid fa-envelope contacticon"></i>
            </div>
            <Link
              className="contactmail pt-1 ps-2"
              to={`mailto:admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
            >
              admin@brookshealthandcare.com
            </Link>
          </div>
          <div className="py-4">
            <div className="d-flex contacticoncontainer ">
              <div>
                <i class="fa-solid fa-phone contacticon"></i>
              </div>
              <div className="contactmail pt-1 ps-2"> <a className="tellink" href="tel:+441234954004">
                  01234954004
                </a></div>
            </div>
          </div>
          <div className="">
            <div className="d-flex contacticoncontainer ">
              <div>
                <i class="fa-solid fa-location-dot contacticon"></i>{" "}
              </div>
              <div className="contactmail pt-1 ps-2">
                Juniper walk, Kempston, Bedford. MK42 7SX
              </div>
            </div>
          </div>
          <div className="contactmail pt-5 pb-3">Follow Us on Social Media</div>
          <div className="contacticons d-flex ">
            <div>
              <a
                href="https://www.facebook.com/profile.php?id=61557096390057"
                class="fa-brands fa-facebook contacticon"
              ></a>{" "}
            </div>
            <div>
              <a
                href="https://www.instagram.com/brookshealthandcareltd?igsh=ajV4YjVhZ2Exc2tp&utm_source=qr"
                class="fa-brands fa-instagram contacticon"
              ></a>{" "}
            </div>
            <div>
              <a
                href="https://api.whatsapp.com/send?phone=447961929342"
                class="fa-brands fa-whatsapp contacticon"
              ></a>{" "}
            </div>
          </div>
        </div>
        <div className="pt-4 pt-lg-1 slideRight">
          <form
            action="https://formspree.io/f/xjvnjbev"
            method="POST"
            className="contactform"
          >
            <div className="formgroup">
              <div className="formgroupsection pb-3 pb-md-1">
                <label for="fname">First Name</label>
                <input
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="Enter First Name"
                  className="mobileinput"
                />
              </div>
              <div className="formgroupsection ">
                <label for="lname">Last Name</label>
                <input
                  type="text"
                  id="lname"
                  name="lastname"
                  placeholder="Enter Last Name"
                  className="mobileinput"
                />
              </div>
            </div>

            <div className="formgroupsection">
              <label for="email">Email</label>
              <input
                type="email"
                id="emaill"
                name="email"
                placeholder="Enter Your Email "
                className="maxwidth"
              />
            </div>
            <div className="formgroupsection pt-3">
              <label for="email">Phone</label>
              <input
                type="phone"
                id="phone"
                name="phone"
                placeholder="Enter Your Phone Number "
                className="maxwidth"
              />
            </div>
            <div className="formgroupsection pt-3">
              <label for="message">Message</label>
              <textarea
                className="messageform"
                id="message"
                name="message"
                placeholder="Enter your Message here..."
              ></textarea>
            </div>
            <div className="contactformbtn pt-3">
              <button className="testimonialbtn footertestimonialbtn">
                <span className="pe-2">Send Message</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow footerhomeSegment6testimoialarrow"
                />
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* --------------HOME SEGMENT 6-------------- */}
      <div className="homeSegment6  p-md-5 p-4">
        <div className="d-flex">
          <div className="homeSegment1aboutusline"></div>
          <div className="homeSegment1aboutus ps-2">REVIEWS </div>
        </div>
        <div className="d-flex homeSegment6container">
          <div className="homeSegment3intro py-3">
            Our commitment to excellence is reflected <br />
            in the words of our satisfied service users{" "}
          </div>

          <div className="pt-2 pt-md-5">
            <a
              href="https://www.homecare.co.uk/homecare/agency.cfm/id/65432245376#reviews"
              className="testimonialbtn"
            >
              <span className="pe-2">View All Testimonials</span>{" "}
              <img
                src={uprightarrow}
                alt="arrow"
                className="homeSegment6testimoialarrow"
              />
            </a>
          </div>
        </div>

        <div className="testimonials pt-3">
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialtitle pt-4">Compassionate</div>
            <div className="testimonialparagraph pt-3">
              My husband is currently being cared for by a live-in carer, giving
              me a week of respite. The care agency listened to our needs and
              have provided a carer who has the right experience, and attitude.
              Her approach and understanding shows exceptional care and
              compassion, and I have full confidence in her, knowing that she
              will respect his wishes and look after his needs.
            </div>
            <div className="testimonialperson pt-4 d-flex">
              <div></div>
              <div className="pt-3">
                <div className="testimonialname"> C S </div>
                {/* <div className="testimonialplace">USA, California</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialtitle pt-4">Attentive Care</div>
            <div className="testimonialparagraph pt-3">
              We have had home care for two weeks for my mother following her
              time in hospital. It is early days to comment but overall we have
              been pleased with the care that they have provided. Difficult to
              give a more detailed report as we have had a variety of carers up
              until now.
            </div>
            <div className="testimonialperson pt-4 ">
              <div className="pt-3">
                <div className="testimonialname">Gillian B </div>
                {/* <div className="testimonialplace">USA, Florida</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialtitle pt-4">Reliable Support</div>
            <div className="testimonialparagraph pt-3">
              Good and punctual service. The carer is friendly, polite and
              helpful, would recommend your service to other clients.
            </div>
            <div className="testimonialperson pt-4 ">
              <div className="pt-3">
                <div className="testimonialname">B B</div>
                {/* <div className="testimonialplace">USA, Nevada</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --------------HOME SEGMENT 7-------------- */}
      <div className="homeSegment7  p-md-5 p-4">
        <div className="d-flex pt-4">
          <div className="homeSegment1aboutusline"></div>
          <div className="homeSegment1aboutus ps-2">
            FREQUENTLY ASKED QUESTIONS
          </div>
        </div>
        <div className="homeSegment3intro py-3">
          Get answers about Brooks Health and Care's <br />
          care services.{" "}
        </div>

        <div className="FAQs">
          <div className="py-2 px-5">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <div className="d-md-flex">
                        <div className="px-5 FAQNo">01</div>
                        <div className=" ps-4 faqanswertitle pt-3">
                          What types of services does Brooks Health and Care
                          offer?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      We offer a range of home health care services, including
                      Care Services, Personalized Caregiving, Medical Staffing,
                      and Palliative Care Services. Our services are tailored to
                      meet the unique needs and preferences of each individual
                      we serve.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-5">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOneB">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div className="d-md-flex">
                        <div className="px-5 FAQNo">02</div>
                        <div className=" ps-4 faqanswertitle pt-3">
                          How do I know if home health care is right for me or
                          my loved one?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOneB"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body  pt-3 pb-4 ">
                      Home health care may be suitable for individuals who
                      require assistance with activities of daily living,
                      medical support, or companionship in the comfort of their
                      own homes. We offer free consultations to assess your
                      needs and discuss the best care options for you or your
                      loved one.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-5">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOneC">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <div className="d-md-flex">
                        <div className="px-5 FAQNo">03</div>
                        <div className=" ps-4 faqanswertitle pt-3">
                          Are your caregivers trained and certified?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body  pt-3 pb-4 ">
                      Yes, all our caregivers are highly trained, experienced,
                      and certified professionals. They undergo thorough
                      background checks and regular training to ensure they
                      provide the highest quality of care to our service users.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-5">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOneD">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <div className="d-md-flex">
                        <div className="px-5 FAQNo">04</div>
                        <div className=" ps-4 faqanswertitle pt-3">
                          How do I arrange for home health care services?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body  pt-3 pb-4 ">
                      You can arrange for our services by contacting us directly
                      via phone or email. We will schedule a consultation to
                      assess your needs and develop a personalized care plan
                      that meets your requirements.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-5">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOneE">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <div className="d-md-flex">
                        <div className="px-5 FAQNo">05</div>
                        <div className=" ps-4 faqanswertitle pt-3">
                          Is home health care covered by insurance?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOneE"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body  pt-3 pb-4 ">
                      Home health care coverage varies depending on your
                      insurance provider and your specific policy. We recommend
                      contacting your insurance company to verify coverage
                      details. We also offer flexible payment options to
                      accommodate your financial situation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-5">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOneF">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <div className="d-md-flex">
                        <div className="px-5 FAQNo">06</div>
                        <div className=" ps-4 faqanswertitle pt-3">
                          Can I request a specific caregiver?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOneF"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body  pt-3 pb-4 ">
                      Yes, we understand the importance of building trust and
                      rapport with your caregiver. While we cannot guarantee the
                      availability of a specific caregiver at all times, we will
                      do our best to accommodate your preferences whenever
                      possible.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-5">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOneE">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      <div className="d-md-flex">
                        <div className="px-5 FAQNo">07</div>
                        <div className=" ps-4 faqanswertitle pt-3">
                          How can I provide feedback or address concerns about
                          the care I or my loved one received?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOneG"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body  pt-3 pb-4 ">
                      We welcome feedback from our service users and their
                      families. You can contact our customer service team
                      directly to provide feedback, address concerns, or make
                      suggestions for improvement. Your satisfaction and
                      well-being are important to us, and we are committed to
                      addressing any issues promptly.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
