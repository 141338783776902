import React from "react";
import GreenArrow from "../images/greenarrow.png";
import GgreenArrow from "../images/GgreenArrow.png";
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
// import Animate from 'animate.css-react';
import 'animate.css/animate.compat.css'; // Import Animate.css styles
import {Animated} from "react-animated-css";
import servicessegment1 from "../images/servicessegment1.jpg";
import uprightarrow from "../images/uprightarrow.png";
import homeSegment5IMG from "../images/homeSegment5IMG.jpg";
import homeSegment4arrow from "../images/homeSegment4arrow.png";
import serviceshero from "../images/serviceshero.jpg";
import HomeSegment2IMG from "../images/homeSegment2IMG.jpg";

import { Link } from "react-router-dom";

function Services() {
  return (
    <div>
    
      <div className="aboutheroreplacement p-md-5 p-4"  style={{
            backgroundImage: `url(${serviceshero})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            backgroundColor: "rgb(0 0 0 / 70%)"
            
          }}>
      <div className="aboutheroreplacementbox">
              <div className="lhsheading">
                {" "}
                <span class="thick-dot"></span>{" "}
                <span className="ps-1"> BROOKS HEALTH AND CARE SERVICES</span>{" "}
              </div>
              <div className="lhssubtitle pb-1 pt-3">
              Our Comprehensive Care Services{" "}
              </div>
            </div>
      </div>

      <div className="aboutsegment1 p-4 p-md-5">
        Welcome to Brooks Health and Care's Services Page. Explore our
        comprehensive range of care services designed to meet your unique needs
        and preferences. From personalized caregiving to medical staffing
        solutions, our dedicated team is here to provide compassionate care and
        support for you and your loved ones.
      </div>
      <div className="horizontal-border-line pt-5"></div>

      {/* --------------SERVICES SEGMENT 2-------------- */}
      <div className="homeSegment2 p-md-5 p-4 d-grid">
              <ScrollAnimation className="homeSegment2order2" animateIn="bounceInLeft" animationOut="fadeOut">


            <div className=" homeSegment2container pb-4">
              <div>
                <div className="homeSegment2numbers">95%</div>
                <div className="homeSegment2subtext">Clients Satisfied</div>
              </div>
              <div>
                <div className="homeSegment2numbers">200k+</div>
                <div className="homeSegment2subtext">Care Hours Delivered</div>
              </div>
              <div>
                <div className="homeSegment2numbers">3+</div>
                <div className="homeSegment2subtext">Years of Experience</div>
              </div>
            </div>
            <div className="homeSegment2textContainer">
              <div className="homeSegment2text pt-2 pt-md-5">
                Ready to experience the warmth of compassionate care and
                personalized support?
              </div>
              <a
                href="/contact"
                className="homeSegment2contactcontainer pb-1 pt-4"
              >
                <div className="homeSegment2ccontact">Contact Us</div>
                <div>
                  <img className="" src={GgreenArrow} alt="arrow" />
                </div>
              </a>
              <div className="homeSegment2textContainerline"></div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="homeSegment2order1" animateIn="bounceInRight" animationOut="fadeOut">
            <img
              className="HomeSegment2IMG"
              src={HomeSegment2IMG}
              alt="A Care Worker"
            />
          </ScrollAnimation>
        </div>
      <div className="greybg">
        {/* --------------SERVICES SEGMENT 2--------------   */}
        <div className="servicessegment2 p-4 p-md-5">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">Care Services </div>
          </div>
          <div className="d-md-flex homeSegment6container">
            <div className="homeSegment3intro py-3">
              Discover the comfort of home with our <br /> care services.
            </div>
            <div className="pt-3 pt-md-5">
              <Link
                className=" py-3 testimonialbtn abouttestimonial"
                to={`mailto:admin@brookshealthandcare.com`}
              >
                <span className="pe-2 ">Book Appointment</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </Link>
            </div>
          </div>
          <div className="aboutsegment3container py-5 py-md-4">
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="aboutsegment3icon fa-solid fa-heart-circle-check"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Direct Home Care Support
              </div>
              <div className="aboutsegment3boxdesc">
                Providing assistance with activities of daily living in the
                comfort of your own home.
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid aboutsegment3icon fa-address-card"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Personal Care</div>
              <div className="aboutsegment3boxdesc">
                Tailored personal care services to meet your specific needs.
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-people-group aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Live-in Care</div>
              <div className="aboutsegment3boxdesc">
                24/7 care and support from a dedicated caregiver who resides in
                your home.
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-address-card aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Companionship</div>
              <div className="aboutsegment3boxdesc">
                Social interaction and companionship to combat loneliness and
                isolation.
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-star aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Respite</div>
              <div className="aboutsegment3boxdesc">
                Temporary relief for primary caregivers, allowing them to take a
                break while their loved one receives care.
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-circle-check aboutsegment3icon"></i>{" "}
              </div>
              <div className="aboutsegment3boxtitle py-3">Nights/Sleep-in</div>
              <div className="aboutsegment3boxdesc">
                Overnight care services for peace of mind and support during the
                night.
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-address-card aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Wake-in Nights</div>
              <div className="aboutsegment3boxdesc">
                Assistance and support during the night for those who require
                wakeful supervision.{" "}
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-star aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">1-1 Support</div>
              <div className="aboutsegment3boxdesc">
                Individualized support tailored to your unique needs and
                preferences.{" "}
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-circle-check aboutsegment3icon"></i>{" "}
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Care Support for Travel, and more.
              </div>
              <div className="aboutsegment3boxdesc">
                Accompanying individuals on trips and providing support during
                travel.
              </div>
            </ScrollAnimation>
          </div>
        </div>

        {/* --------------SERVICES SEGMENT 3--------------   */}
        <div className="servicessegment2 p-4 p-md-5">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">
              AGENCY SUPPLY SERVICE{" "}
            </div>
          </div>
          <div className="d-md-flex  homeSegment6container">
            <div className="homeSegment3intro py-3">
              Optimize staffing with our Agency <br /> Supply Service.
            </div>
            <div className="pt-3 pt-md-5">
              <Link
                className=" py-3 testimonialbtn "
                to={`mailto:admin@brookshealthandcare.com`}
              >
                <span className="pe-2 ">Book Appointment</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </Link>
            </div>
          </div>
          <div className="aboutsegment3container py-4">
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="aboutsegment3icon fa-solid fa-heart-circle-check"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Covering Staff</div>
              <div className="aboutsegment3boxdesc">
                Providing coverage for healthcare facilities to ensure optimal
                staffing levels at all times.
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid aboutsegment3icon fa-address-card"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Supply of Temporary and Permanent Staff
              </div>
              <div className="aboutsegment3boxdesc">
                Supplying qualified healthcare professionals for temporary or
                permanent positions as needed.{" "}
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-people-group aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Temp to Permanent Staff
              </div>
              <div className="aboutsegment3boxdesc">
                Offering temporary staffing solutions with the option to
                transition to permanent employment.
              </div>
            </ScrollAnimation>
          </div>
        </div>
        {/* --------------SERVICES SEGMENT 4--------------   */}
        <div className="servicessegment2 p-4 p-md-5">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">TYPES OF STAFF </div>
          </div>
          <div className="d-md-flex homeSegment6container">
            <div className="homeSegment3intro py-3">
              Meet our diverse team of dedicated professionals.{" "}
            </div>
            <div className="pt-md-5 py-3">
              <Link
                className=" py-3 testimonialbtn "
                to={`mailto:admin@brookshealthandcare.com`}
              >
                <span className="pe-2 ">Book Appointment</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </Link>
            </div>
          </div>
          <div className="aboutsegment3container py-4">
          <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="aboutsegment3icon fa-solid fa-heart-circle-check"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Healthcare Assistant
              </div>
              <div className="aboutsegment3boxdesc">
                Assisting with activities of daily living and providing basic
                medical care under the supervision of a registered nurse.{" "}
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid fa-people-group aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Nurses</div>
              <div className="aboutsegment3boxdesc">
                Providing specialized care and support for individuals with
                health conditions.{" "}
              </div>
            </ScrollAnimation>
            <ScrollAnimation className="aboutsegment3box" animateIn="bounceInUp" animationOut="fadeOut">
              <div>
                <i class="fa-solid aboutsegment3icon fa-address-card"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Support Worker</div>
              <div className="aboutsegment3boxdesc">
                Offering emotional support, companionship, and assistance with
                daily tasks for individuals in need.{" "}
              </div>
            </ScrollAnimation>
          </div>
        </div>
      
        {/* --------------HOME SEGMENT 4-------------- */}
        <div className="pb-5">
          <div className="homeSegment4 p-4 p-md-5">
            <div className="homeSegment4container p-4">
              <div>
                <div className="homeSegment4title">Brooks Health and Care</div>
                <div className="homeSegment4subtitle pt-2">
                  Let's Discuss Your Health Needs
                </div>
              </div>
              <Link
                to={`mailto: admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
                className="homeSegment4contactUs"
              >
                Contact Us{" "}
                <img className="ps-2" src={homeSegment4arrow} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
