import React from "react";

function NotFound() {
    return(
        <div>
            Our NotFound
        </div>
    )
    
  }
  export default NotFound;