import React from "react";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";
// import Animate from 'animate.css-react';
import "animate.css/animate.compat.css"; // Import Animate.css styles
import { Animated } from "react-animated-css";
import uprightarrow from "../images/uprightarrow.png";
import GreenArrow from "../images/greenarrow.png";
import homeSegment4arrow from "../images/homeSegment4arrow.png";
import jobhero from "../images/jobhero.jpg";
import vacancy from "../images/vacancy.jpg";
import applicationform from "../images/ApplicationForm.pdf";
import { Link } from "react-router-dom";

function Job() {
  return (
    <div>
      {/* <div>
        <div class="">
          <img
            src={jobhero}
            class="d-block w-100 aboutheroimg"
            alt="An aged woman experiencing peace of mind."
          />
          <div class="carousel-caption d-block">
            <div className="lhs lhsmobile">
              <div className="lhsheading">
                {" "}
                <span class="thick-dot"></span>{" "}
                <span className="ps-1">
                  {" "}
                  JOIN OUR TEAM AT BROOKS HEALTH AND CARE{" "}
                </span>{" "}
              </div>
              <div className="lhssubtitle pt-3">
                Explore Exciting Career Opportunities with Brooks{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="aboutheroreplacement p-md-5 p-4"
        style={{
          backgroundImage: `url(${jobhero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          backgroundColor: "rgb(0 0 0 / 70%)",
        }}
      >
        <div className="aboutheroreplacementbox">
          <div className="lhsheading">
            {" "}
            <span class="thick-dot"></span>{" "}
            <span className="ps-1">
              JOIN OUR TEAM AT BROOKS HEALTH AND CARE{" "}
            </span>{" "}
          </div>
          <div className="lhssubtitle pt-3">
            Explore Exciting Career Opportunities with Brooks{" "}
          </div>
        </div>
      </div>

      {/* ----------------JOB SEGMENT 1--------------- */}
      <div className="aboutsegment1 px-5  pt-4 pb-3">
        Welcome to our Careers page! Discover fulfilling opportunities to join
        our dynamic team and contribute to our mission of Brooks Health and
        Care. Explore our current job openings below and take the first step
        towards an exciting career with us.
      </div>
      <div className="horizontal-border-line pt-3"></div>
      <div className="greybg">
        {/* ----------------JOB SEGMENT 2--------------- */}
        <div className="jobsegment1 p-4 p-md-5">
          <div className="jobsegment2title">Current Job Openings</div>
          <div className="jobsegment2container pt-4  ">
            <div className="jobsegment2box p-md-5 p-3">
              <div className="d-flex jobsegment2sub1 ">
                <div>
                  <div className="jobtitle pb-1">
                    {" "}
                    Domiciliary Care Worker with Full UK Driving Licence
                  </div>
                  <div className="jobsegment2desc py-2">
                    We are seeking a dedicated and compassionate Domiciliary
                    Care Worker with a full and valid UK driving licence to join
                    our team. As a Domiciliary Care Worker, you will provide
                    personal care and support to clients in their homes,
                    promoting their independence and enhancing their quality of
                    life. This role requires travel between clients’ homes, so a
                    valid UK driving licence and access to a reliable vehicle
                    are essential.
                  </div>
                  <a
                    href="/jobdesc"
                    className="homeSegment3contactcontainer  pt-4"
                  >
                    <div className="homeSegment2contact pe-3">
                      See Full Details
                    </div>
                    <div>
                      <img className="" src={GreenArrow} alt="arrow" />
                    </div>
                  </a>
                </div>
                <div className="pt-3 pt-md-5">
                  <a
                    href={applicationform}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="applicationlink"
                  >
                    <button className="testimonialbtn d-flex">
                      <span className="pe-2">Apply </span>{" "}
                      <img
                        src={uprightarrow}
                        alt="arrow"
                        className="homeSegment6testimoialarrow"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vacancydiv">
          <img src={vacancy} className="vacancy" alt="" />
        </div>

        {/* ----------------JOB SEGMENT 3--------------- */}
        <div className="aboutsegment3 p-4 p-md-5">
          <div className="d-flex pt-5 ">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2 ">
              WHY JOIN US AT BROOKS HEALTH AND CARE?{" "}
            </div>
          </div>
          <div className="homeSegment3intro py-4">
            At Brooks Health and Care, we're a dedicated <br />
            community making a difference. Here's why you <br /> should join us:
          </div>
          <div className="aboutsegment3container">
            <ScrollAnimation
              className="aboutsegment3box"
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div>
                <i class="aboutsegment3icon fa-solid fa-heart-circle-check"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">Meaningful Work</div>
              <div className="aboutsegment3boxdesc">
                Every day, our team members at Brooks Health and Care have the
                opportunity to make a positive impact on the lives of others.
                Whether it's providing compassionate care to our service users
                or developing innovative solutions, your work will have purpose
                and significance.
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className="aboutsegment3box"
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div>
                <i class="fa-solid aboutsegment3icon fa-address-card"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Collaborative Environment
              </div>
              <div className="aboutsegment3boxdesc">
                We believe in the power of collaboration and teamwork at Brooks
                Health and Care. You'll have the chance to work alongside
                talented individuals who are passionate about what they do,
                share ideas, and support each other's growth and success.
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className="aboutsegment3box"
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div>
                <i class="fa-solid fa-people-group aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Professional Development
              </div>
              <div className="aboutsegment3boxdesc">
                At Brooks Health and Care, we invest in our employees' growth
                and development. From ongoing training opportunities to
                mentorship programs, we're committed to helping you reach your
                full potential and achieve your career goals.
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className="aboutsegment3box"
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div>
                <i class="fa-solid fa-address-card aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Inclusive Culture
              </div>
              <div className="aboutsegment3boxdesc">
                Diversity and inclusion are at the heart of everything we do at
                Brooks Health and Care. We celebrate differences, foster a
                culture of respect and belonging, and strive to create an
                environment where everyone feels valued and empowered to
                succeed.
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className="aboutsegment3box"
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div>
                <i class="fa-solid fa-star aboutsegment3icon"></i>
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Work-Life Balance
              </div>
              <div className="aboutsegment3boxdesc">
                We understand the importance of maintaining a healthy work-life
                balance at Brooks Health and Care. That's why we offer flexible
                scheduling options, generous time off, and supportive policies
                that prioritize your well-being and allow you to thrive both
                personally and professionally.
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className="aboutsegment3box"
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div>
                <i class="fa-solid fa-circle-check aboutsegment3icon"></i>{" "}
              </div>
              <div className="aboutsegment3boxtitle py-3">
                Competitive Benefits
              </div>
              <div className="aboutsegment3boxdesc">
                In addition to a rewarding work experience, we offer competitive
                compensation packages and a comprehensive benefits program at
                Brooks Health and Care. From healthcare coverage to retirement
                savings plans, we provide the support and resources you need to
                thrive.
              </div>
            </ScrollAnimation>
          </div>
        </div>
        {/* ----------------JOB SEGMENT 4--------------- */}
        <div className="jobsegment4 px-5 py-4">
          Join us at Brooks Health and Care and become part of a team that's
          making a difference in the lives of others every day. Together, we can
          create a brighter future for our community and beyond.
        </div>
        {/* --------------HOME SEGMENT 4-------------- */}
        <div className="pb-5">
          <div className="homeSegment4 p-4 p-md-5">
            <div className="homeSegment4container p-4">
              <div>
                <div className="homeSegment4title">
                  If you have any questions about our job openings or the
                  application process{" "}
                </div>
                <div className="homeSegment4subtitle pt-2">
                  Please don't hesitate to contact us{" "}
                </div>
              </div>
              <Link
                to={`mailto: admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
                className="homeSegment4contactUs"
              >
                Contact Us{" "}
                <img className="ps-2" src={homeSegment4arrow} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Job;
