import React from "react";
import '../App.css';

function Header() {
    return (
      <div>
        {/* -----------HEADER---------------- */}
        <div>
            
        </div>
      </div>
    );
  }

  export default Header;