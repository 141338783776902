import React from "react";
import "../App.css";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";
// import Animate from 'animate.css-react';
import "animate.css/animate.compat.css"; // Import Animate.css styles
import { Animated } from "react-animated-css";

// import 'animate.css/animate.css';
import HomeSegment2IMG from "../images/homeSegment2IMG.jpg";
import GreenArrow from "../images/greenarrow.png";
import GgreenArrow from "../images/GgreenArrow.png";

import homeSegment3img1 from "../images/homeSegment3img1s.jpg";
import homeSegment3img2 from "../images/homeSegment3img2.jpg";
import homeSegment3img3 from "../images/homeSegment3img3.jpg";
import homeSegment3img4 from "../images/homeSegment3img4.jpg";
import homeSegment4arrow from "../images/homeSegment4arrow.png";
import homeSegment5IMG from "../images/homeSegment5IMG.jpg";
import secondimg from "../images/secondimg.jpg";
import homeSegment5IMGii from "../images/homeSegment5IMGii.jpg";
import uprightarrow from "../images/uprightarrow.png";
import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import partner1 from "../images/partner1.PNG";
import partner2 from "../images/partner2.PNG";
import partner3 from "../images/partner3.PNG";

import star from "../images/star.png";
import heroboximg1 from "../images/heroboximg1.jpg";
import heroboximg2 from "../images/newheroboximg2.jpg";
import heroboximg3 from "../images/newheroboximg3.jpg";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <div>
        {/* ----------------HERO BOX----------------- */}
        <div className="herobox">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel carousel-fade slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src={heroboximg1}
                  class="d-block w-100 heroimg"
                  alt="An aged woman experiencing peace of mind."
                />
                <div class="carousel-caption  d-block">
                  <div className="lhsmobile">
                    <div className="lhsheading">
                      {" "}
                      <span class="thick-dot"></span>{" "}
                      <span className="ps-1">
                        {" "}
                        HEALTH AND CARE COMPANIONSHIP{" "}
                      </span>{" "}
                    </div>
                    <div className="lhssubtitle">
                      Embracing Your Journey to Wellness
                    </div>
                  </div>
                  <div className="rhs pb-5">
                    <div className="rhsdesc">
                      {" "}
                      Welcome to Brooks Health and Care, where we understand
                      that your well-being is unique and deserves personalized
                      attention.
                    </div>
                    <div className="rhsbtn">
                      <div className="pt-3">
                        <a className="testimonialbtn py-3" href="/contact">
                          <span className="pe-2 ">Book Appointment</span>{" "}
                          <img
                            src={uprightarrow}
                            alt="arrow"
                            className="homeSegment6testimoialarrow"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item ">
                <img
                  src={heroboximg2}
                  class="d-block w-100 heroimg"
                  alt="An aged woman experiencing peace of mind."
                />
                <div class="carousel-caption d-block">
                  <div className="lhs">
                    <div className="lhsheading">
                      {" "}
                      <span class="thick-dot"></span>{" "}
                      <span className="ps-1">
                        {" "}
                        HEALTH AND CARE COMPANIONSHIP{" "}
                      </span>{" "}
                    </div>
                    <div className="lhssubtitle">
                      Embracing Your Journey to Wellness
                    </div>
                  </div>
                  <div className="rhs pb-5">
                    <div className="rhsdesc">
                      {" "}
                      Welcome to Brooks Health and Care, where we understand
                      that your well-being is unique and deserves personalized
                      attention.
                    </div>
                    <div className="rhsbtn">
                      <div className="pt-3">
                        <a className="testimonialbtn py-3" href="/contact">
                          <span className="pe-2">Book Appointment</span>{" "}
                          <img
                            src={uprightarrow}
                            alt="arrow"
                            className="homeSegment6testimoialarrow"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div class="carousel-item ">
                <img
                  src={heroboximg3}
                  class="d-block w-100 heroimg"
                  alt="An aged woman experiencing peace of mind."
                />
                <div class="carousel-caption d-block">
                  <div className="lhs">
                    <div className="lhsheading">
                      {" "}
                      <span class="thick-dot"></span>{" "}
                      <span className="ps-1">
                        {" "}
                        HEALTH AND CARE COMPANIONSHIP{" "}
                      </span>{" "}
                    </div>
                    <div className="lhssubtitle">
                      Embracing Your Journey to Wellness
                    </div>
                  </div>
                  <div className="rhs pb-5">
                    <div className="rhsdesc">
                      {" "}
                      Welcome to Brooks Health and Care, where we understand
                      that your well-being is unique and deserves personalized
                      attention.
                    </div>
                    <div className="rhsbtn">
                      <div className="pt-3">
                        <a href="/contact" className="testimonialbtn py-3">
                          <span className="pe-2">Book Appointment</span>{" "}
                          <img
                            src={uprightarrow}
                            alt="arrow"
                            className="homeSegment6testimoialarrow"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --------------HOME SEGMENT 1-------------- */}
        <div className="homeSegment1  p-md-5 p-4">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">About us</div>
          </div>
          <div className="homeSegment1text pt-4">
            We are a reliable company that provides compassionate and
            personalised healthcare services. Our range of services is tailored
            to meet the unique needs of each individual we serve, including Home
            Care Support, Personalised Caregiving, and Medical Staffing.
            <br /> <br />
            Our ultimate goal is to provide exceptional care to those who depend
            on us, ensuring they receive unparalleled service and support. At
            Brooks Health and Care, we understand that your health and wellbeing
            are of utmost importance. That's why we are dedicated to providing
            you with unwavering support, listening to your concerns, and working
            collaboratively with you to meet your unique needs. We're here to
            help you make informed decisions and put you back in control of your
            health journey.
            <br /> <br />
            We know that empowerment is the key to having control over the
            decisions and actions that shape our lives.
            <br /> <span className="fw-bold">WE ARE HERE FOR YOU.</span>
          </div>
          <div className="homeSegment1text pt-3"></div>
          <div className="horizontal-border-line pt-5"></div>
        </div>

        {/* --------------HOME SEGMENT 2-------------- */}
        <div className="homeSegment2 p-md-5 p-4 d-grid">
          <ScrollAnimation
            className="homeSegment2order2"
            animateIn="bounceInLeft"
            animationOut="fadeOut"
          >
            <div className=" homeSegment2container pb-4">
              <div>
                <div className="homeSegment2numbers">95%</div>
                <div className="homeSegment2subtext">Clients Satisfied</div>
              </div>
              <div>
                <div className="homeSegment2numbers">200k+</div>
                <div className="homeSegment2subtext">Care Hours Delivered</div>
              </div>
              <div>
                <div className="homeSegment2numbers">3+</div>
                <div className="homeSegment2subtext">Years of Experience</div>
              </div>
            </div>
            <div className="homeSegment2textContainer">
              <div className="homeSegment2text pt-2 pt-md-5">
                Ready to experience the warmth of compassionate care and
                personalized support?
              </div>
              <a
                href="/contact"
                className="homeSegment2contactcontainer pb-1 pt-4"
              >
                <div className="homeSegment2ccontact">Contact Us</div>
                <div>
                  <img className="" src={GgreenArrow} alt="arrow" />
                </div>
              </a>
              <div className="homeSegment2textContainerline"></div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className="homeSegment2order1"
            animateIn="bounceInRight"
            animationOut="fadeOut"
          >
            <img
              className="HomeSegment2IMG"
              src={HomeSegment2IMG}
              alt="A Care Worker"
            />
          </ScrollAnimation>
        </div>
        {/* --------------HOME SEGMENT 3-------------- */}
        <div className="homeSegment3  p-md-5 p-4">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">WHAT WE DO</div>
          </div>
          <div className="homeSegment3intro py-3">
            At Brooks Health and Care,
            <br /> we prioritize your well-being above all else.
          </div>
          <div className="homeSegment3container pt-2">
            <ScrollAnimation
              className="homeSegment3box p-3"
              animateIn="bounceInLeft"
              animationOut="fadeOut"
            >
              <div className="homeSegment3imgcontainer">
                <img
                  className="homeSegment3img"
                  src={homeSegment3img1}
                  alt="careworker"
                />
              </div>
              <div className="homeSegment3title py-2"> Care Services</div>
              <div className="homeSegment3subtitle">
                We provide compassionate and reliable care services services,
                enabling our clients to live independently and comfortably in
                their homes.
              </div>
              <a
                href="/contact"
                className="homeSegment3contactcontainer pb-1 pt-4"
              >
                <div className="homeSegment2contact pe-4">Get Started</div>
                <div>
                  <img className="" src={GreenArrow} alt="arrow" />
                </div>
              </a>
            </ScrollAnimation>
            <ScrollAnimation
              className="homeSegment3box2 p-3"
              animateIn="bounceInRight"
              animationOut="fadeOut"
            >
              <div className="homeSegment3imgcontainer">
                <img
                  className="homeSegment3img"
                  src={homeSegment3img2}
                  alt="careworker"
                />
              </div>
              <div className="homeSegment3title py-2">
                Personalized Caregiving
              </div>
              <div className="homeSegment3subtitle">
                Our personalised caregiving services are designed to cater to
                each Service User’s unique needs and preferences, ensuring that
                they receive the highest quality of care possible.
              </div>
              <a
                href="/contact"
                className="homeSegment3contactcontainer pb-1 pt-4"
              >
                <div className="homeSegment2contact pe-4">Get Started</div>
                <div>
                  <img className="" src={GreenArrow} alt="arrow" />
                </div>
              </a>
            </ScrollAnimation>
            <ScrollAnimation
              className="homeSegment3box p-3"
              animateIn="bounceInLeft"
              animationOut="fadeOut"
            >
              <div className="homeSegment3imgcontainer">
                <img
                  className="homeSegment3img"
                  src={homeSegment3img3}
                  alt="careworker"
                />
              </div>
              <div className="homeSegment3title py-2">Medical Staffing</div>
              <div className="homeSegment3subtitle">
                Our exceptional medical staffing team guarantees that you
                receive the best care and support possible. With years of
                experience and expert knowledge, we ensure that you and your
                loved ones are in safe hands, giving you the peace of mind you
                deserve.
              </div>
              <a
                href="/contact"
                className="homeSegment3contactcontainer pb-1 pt-4"
              >
                <div className="homeSegment2contact pe-4">Get Started</div>
                <div>
                  <img className="" src={GreenArrow} alt="arrow" />
                </div>
              </a>
            </ScrollAnimation>
            <ScrollAnimation
              className="homeSegment3box2 p-3"
              animateIn="bounceInRight"
              animationOut="fadeOut"
            >
              <div className="homeSegment3imgcontainer">
                <img
                  className="homeSegment3img"
                  src={homeSegment3img4}
                  alt="careworker"
                />
              </div>
              <div className="homeSegment3title py-2">
                Palliative Care Services
              </div>
              <div className="homeSegment3subtitle">
                Our palliative care services are designed to provide the utmost
                comfort and support to both patients and their loved ones. We
                understand that difficult times call for compassionate care, and
                we are here to help you navigate through them with ease.
              </div>
              <a
                href="/contact"
                className="homeSegment3contactcontainer pb-1 pt-4"
              >
                <div className="homeSegment2contact pe-4">Get Started</div>
                <div>
                  <img className="" src={GreenArrow} alt="arrow" />
                </div>
              </a>
            </ScrollAnimation>
          </div>
        </div>
        {/* --------------HOME SEGMENT 4-------------- */}
        <div className="homeSegment4  p-md-5 p-4">
          <div className="homeSegment4container p-4">
            <div>
              <div className="homeSegment4title">Brooks Health and Care</div>
              <div className="homeSegment4subtitle pt-2">
                Let's Discuss Your Health Needs
              </div>
            </div>
            <Link
              to={`mailto: admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
              className="homeSegment4contactUs"
            >
              Contact Us <img className="ps-2" src={homeSegment4arrow} alt="" />
            </Link>
          </div>
        </div>
        {/* --------------HOME SEGMENT 5-------------- */}
        <div className="homeSegment5  p-md-5 p-4">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">WHY CHOOSE BROOKS?</div>
          </div>
          <div className="homeSegment3intro py-3">
            Discover why Brooks Health and Care is your <br /> top choice for
            health care services.{" "}
          </div>

          <div className="homeSegment5container pt-3">
            <div>
              <img
                src={secondimg}
                alt="Care Worker"
                className="homeSegment5IMG pb-3"
              />
              <img
                src={homeSegment5IMG}
                alt="Care Worker"
                className="homeSegment5IMG"
              />
            </div>
            <ScrollAnimation
              className="homeSegment5container2 "
              animateIn="bounceInRight"
              animationOut="fadeOut"
            >
              <div className="pb-4">
                <div className="homeSegment5box d-flex">
                  <div className="">
                    <div className="homeSegment5title">
                      Comprehensive Services
                    </div>
                    <div className="homeSegment5subtitle pt-3">
                      Brooks Health and Care offers a comprehensive range of
                      services, including care services, personalised
                      caregiving, medical staffing, and palliative care.
                      Whatever your needs, we have the services to support you.
                    </div>
                  </div>
                  <a href="/about">
                    <img src={uprightarrow} alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="pb-4">
                <div className="homeSegment5box d-flex">
                  <div className="">
                    <div className="homeSegment5title">
                      Personalized Approach
                    </div>
                    <div className="homeSegment5subtitle pt-3">
                      We understand that each individual's needs are unique.
                      That's why we take a personalized approach to care,
                      tailoring our services to meet the specific needs and
                      preferences of each client.
                    </div>
                  </div>
                  <a href="/about">
                    <img src={uprightarrow} alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="pb-4">
                <div className="homeSegment5box d-flex">
                  <div className="">
                    <div className="homeSegment5title">Experienced Team</div>
                    <div className="homeSegment5subtitle pt-3">
                      With years of experience in the healthcare industry, our
                      team brings a wealth of knowledge and expertise to every
                      interaction. From support workers, carers to nurses, we
                      have the skills and experience.
                    </div>
                  </div>
                  <a href="/about">
                    <img src={uprightarrow} alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="pb-4">
                <div className="homeSegment5box d-flex">
                  <div className="">
                    <div className="homeSegment5title">Compassionate Care</div>
                    <div className="homeSegment5subtitle pt-3">
                      At Brooks Health and Care, we prioritize compassion in
                      every interaction. Our dedicated team of caregivers and
                      healthcare professionals is committed to providing
                      empathetic and supportive care to our clients.
                    </div>
                  </div>
                  <a href="/about">
                    <img src={uprightarrow} alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="pb-4">
                <div className="homeSegment5box d-flex">
                  <div className="">
                    <div className="homeSegment5title">
                      Commitment to Excellence
                    </div>
                    <div className="homeSegment5subtitle pt-3">
                      We are dedicated to upholding the highest standards of
                      excellence in everything we do. From our rigorous
                      caregiver training programs to our commitment to
                      continuous improvement, we strive for excellence in all
                      aspects.
                    </div>
                  </div>
                  <a href="/about">
                    <img src={uprightarrow} alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="pb-4">
                <div className="homeSegment5box d-flex">
                  <div className="">
                    <div className="homeSegment5title">Trusted Partner</div>
                    <div className="homeSegment5subtitle pt-3">
                      When you choose Brooks Health and Care, We are committed
                      to building long-lasting relationships based on trust,
                      reliability, and exceptional care. For our partners that
                      we supply staff to and our home care service users, you're
                      choosing a trusted partner and experienced professionals
                      ready to deliver quality and exceptional care to the
                      community. We pride ourselves on not disappointing our
                      customers or service users so far.
                    </div>
                  </div>
                  <a href="/about">
                    <img src={uprightarrow} alt="arrow" />
                  </a>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        {/* --------------HOME SEGMENT 6-------------- */}
        <div className="homeSegment6  p-md-5 p-4">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">
REVIEWS            </div>
          </div>
          <div className="d-flex homeSegment6container">
            <div className="homeSegment3intro py-3">
              Our commitment to excellence is reflected <br />
              in the words of our satisfied service users{" "}
            </div>

            <div className="pt-2 pt-md-5">
              <a
                href="https://www.homecare.co.uk/homecare/agency.cfm/id/65432245376#reviews"
                className="testimonialbtn"
              >
                <span className="pe-2">View All Testimonials</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </a>
            </div>
          </div>

          <div className="testimonials pt-3">
            <div className="testimonial">
              <div className="testimonialrating">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>
              <div className="testimonialtitle pt-4">Compassionate</div>
              <div className="testimonialparagraph pt-3">
                My husband is currently being cared for by a live-in carer,
                giving me a week of respite. The care agency listened to our
                needs and have provided a carer who has the right experience,
                and attitude. Her approach and understanding shows exceptional
                care and compassion, and I have full confidence in her, knowing
                that she will respect his wishes and look after his needs.
              </div>
              <div className="testimonialperson pt-4 d-flex">
                <div></div>
                <div className="pt-3">
                  <div className="testimonialname"> C S </div>
                  {/* <div className="testimonialplace">USA, California</div> */}
                </div>
              </div>
            </div>
            <div className="testimonial">
              <div className="testimonialrating">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>
              <div className="testimonialtitle pt-4">Attentive Care</div>
              <div className="testimonialparagraph pt-3">
                We have had home care for two weeks for my mother following her
                time in hospital. It is early days to comment but overall we
                have been pleased with the care that they have provided.
                Difficult to give a more detailed report as we have had a
                variety of carers up until now.
              </div>
              <div className="testimonialperson pt-4 ">
                <div className="pt-3">
                  <div className="testimonialname">Gillian B </div>
                  {/* <div className="testimonialplace">USA, Florida</div> */}
                </div>
              </div>
            </div>
            <div className="testimonial">
              <div className="testimonialrating">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>
              <div className="testimonialtitle pt-4">Reliable Support</div>
              <div className="testimonialparagraph pt-3">
                Good and punctual service. The carer is friendly, polite and
                helpful, would recommend your service to other clients.
              </div>
              <div className="testimonialperson pt-4 ">
                <div className="pt-3">
                  <div className="testimonialname">B B</div>
                  {/* <div className="testimonialplace">USA, Nevada</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------HOME SEGMENT 6.5------------- */}
        <div className="homeSegment6.5  p-md-5 p-4">
          <div className="d-flex">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">OUR PARTNERS </div>
          </div>
          <div className="homeSegment3intro py-3">
            Discover Brooks Health and Care's Partners
          </div>
          <div className="d-md-grid partners pt-4">
          <div className="partner "><div>We are regulated by CQC </div><img className="partnerimg" src={partner2} alt="" /></div>
            <div className="partner "><div>We are registered with the ICO. </div><img className="partnerimg" src={partner1} alt="" /></div>
            <div className="partner "><div>We are registered with Homecare.co.uk </div><img className="partnerimg" src={partner3} alt="" /></div>

          </div>
        </div>
        {/* --------------HOME SEGMENT 7-------------- */}
        <div className="homeSegment7  p-md-5 p-4">
          <div className="d-flex pt-4">
            <div className="homeSegment1aboutusline"></div>
            <div className="homeSegment1aboutus ps-2">
              FREQUENTLY ASKED QUESTIONS
            </div>
          </div>
          <div className="homeSegment3intro py-3">
            Get answers about Brooks Health and Care's <br />
            Services.{" "}
          </div>

          <div className="FAQs">
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">01</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            Will I have to pay for my care?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body pt-3 pb-4 ">
                        You may have to pay for your care; however, you might be
                        eligible for assistance with funding - this will depend
                        on your situation. You will need to contact your local
                        council, who will carry out a care assessment to
                        determine the level of care you require. They will then
                        carry out a financial assessment to determine your
                        ability to pay for or contribute towards the cost of
                        this care. If your loved one is assessed as needing
                        nursing care, regardless of their situation, they may be
                        entitled to either the Funded Nursing Care (FNC)
                        contribution or full funding, depending on their needs.
                      </div>
                    </div>
                  </div>

                  {/* ... other FAQ items with similar structure */}
                </div>
              </div>
            </div>
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneB">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">02</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            How can I provide feedback or address concerns about
                            the care I or my loved one received?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOneB"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body  pt-3 pb-4 ">
                        We welcome feedback from our clients and their families.
                        You can contact our customer service team directly to
                        provide feedback, address concerns, or make suggestions
                        for improvement. Your satisfaction and well-being are
                        important to us, and we are committed to addressing any
                        issues promptly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneC">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">03</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            How do I arrange for health care services?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body  pt-3 pb-4 ">
                        You can arrange for our services by contacting us
                        directly via phone or email. We will schedule a
                        consultation to assess your needs and develop a
                        personalised care plan that meets your requirements.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneD">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">04</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            What are your prices like?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body  pt-3 pb-4 ">
                        A member of our team will be able to give you a
                        ‘starting from’ price if your relative or friend has got
                        to fund the care themselves, so you are able to get an
                        idea of how much the weekly cost will begin from. We
                        then complete a consultation assessment to determine
                        someone’s care and support needs before deciding on how
                        many calls they would need. At this point, we will be
                        able to confirm whether we can meet their needs and the
                        hourly fee. Their fee may increase or decrease in the
                        future, depending on whether their needs increase or
                        decrease. Of course, we will always discuss this with
                        the service user and their next of kin or advocate if it
                        is likely to happen.
                        <div className="pt-2">
                          For any further inquiries, you can reach us on{" "}
                          <a
                            className="text-decoration-underline text-black"
                            href="https://api.whatsapp.com/send?phone=447449367921"
                          >
                            Whatsapp.
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneE">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">05</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            Is there any other financial help available?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOneE"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body  pt-3 pb-4 ">
                        Attendance Allowance is a non-means-tested, tax-free
                        state benefit payable to all people over 65 who have a
                        long-term illness or disability. The weekly amount
                        varies depending on whether someone needs help during
                        the day, at night, or both days and nights. It can
                        continue to be paid, provided the Service User pays for
                        the care themselves and is not funded by the local
                        authority.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneF">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">06</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            What happens if the money runs out?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOneF"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body  pt-3 pb-4 ">
                        Suppose someone is funding their own care, and their
                        savings fall below the amount set by the government. In
                        that case, the local authority has an obligation to fund
                        all or some of the care fees, provided they meet the
                        eligibility criteria. However, the local authority will
                        have a maximum amount that they are prepared to pay, and
                        any shortfall in fees may need to be funded by a family
                        member or friend, known as a ‘third party contribution.’
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneE">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">07</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            How soon can you commence my care?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOneG"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body  pt-3 pb-4 ">
                        The care can be commenced as soon as the assessment is
                        completed and the care packaged is agreed on.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 px-5">
              <div className="FAQ">
                <div
                  className="accordion"
                  id="faqAccordion"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneF">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        <div className="d-md-flex">
                          <div className="px-5 FAQNo">08</div>
                          <div className=" ps-4 faqanswertitle pt-3">
                            Can I pick my carer based on their gender, and can I
                            retain the same carer for my care package?
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOneH"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body  pt-3 pb-4 ">
                        Yes, you can select any gender of carer that you are
                        most comfortable with. You would also be allocated three
                        different carers to enable the rotation of care and get
                        used to them.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
